import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { AppWrapper } from './app/application/AppWrapper';
import { BrowserRouter } from 'react-router-dom';
import activateMocks from './app/services/mocking/activateMocks';
import AppAuthenticator from './app/auth/AppAuthenticator';
import { Amplify } from 'aws-amplify';
import { getAwsEnvironmentConfig } from './app/application/environmentConfig';

if (process.env.NODE_ENV !== "production") {
 activateMocks();
}

Amplify.configure(getAwsEnvironmentConfig());

ReactDOM.render(
  <React.StrictMode>
    <div style={{ height: '100vh', width: '100vw' }}>
      <BrowserRouter>
        <AppWrapper>
          <AppAuthenticator />
        </AppWrapper>
      </BrowserRouter>
    </div>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
