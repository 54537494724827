import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import DataPresentation from '../../components/DataPresentation';
import { usePropsEventsList } from './usePlayerPropsEventSchedule';
import { PropAdminEventGridRecord } from '../../types/until-npm-update/dashBoards/propsEvents';
import { propsEventsColumns } from './propsEventsColumns';

const S = {
  Wrapper: styled.div``,
};

const PropsEventSchedule = () => {
  const { events, isLoading, isError, error } = usePropsEventsList();
  return (
    <S.Wrapper data-testid='PropsEventSchedule'>
      {isLoading && <Typography>Loading...</Typography>}
      {isError && <Typography> Error: ${JSON.stringify(error)}</Typography>}
      {events && (
        <>
          <DataPresentation
            tableId='propsEvents'
            columns={propsEventsColumns}
            rows={events}
            getRowId={(r: PropAdminEventGridRecord) => r.eventId}
            buttonGroupFilters={[{ field: 'sportName', title: 'Filter by ' }]}
            searchKey='eventName'
          />
        </>
      )}
    </S.Wrapper>
  );
};

export default PropsEventSchedule;
