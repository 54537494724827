import { JSONBuilder } from './JSONBuilder';
import { PropAdminActiveGridRecord } from '@superdraft/superdraft-dfs-data-types/lib/dashBoards/props';

// sportName is added by the endpoint.
export type PlayerPropJson = ReturnType<typeof PlayerProp.build> & { sportName: string };

export class PlayerProp extends JSONBuilder<PropAdminActiveGridRecord> {
  static build(json: PropAdminActiveGridRecord) {
    const {
      status,
      sportId,
      eventId,
      propId,
      playerName,
      groupId,
      playerId,
      teamId,
      teamAbbr,
      actualStatsValue,
      winningRequirementValue,
      line,
      choiceActivity,
      question,
    } = json;
    
    const playerProp = new PlayerProp(json);
    const { overUsers, underUsers, overCash, underCash } = playerProp;
    
    return {
      // ...json,
      propId,
      playerName,
      playerId,
      status,
      line,
      sportId: parseInt(sportId),
      overUsers,
      underUsers,
      overCash,
      underCash,
      totalUsers: overUsers + underUsers,
      totalCash: overCash + underCash
    };
  }

  private get overActivity() {
    return this.json.choiceActivity.over;
  }

  private get underActivity() {
    return this.json.choiceActivity.under;
  }

  get overUsers() {
    return this.overActivity.users;
  }

  get underUsers() {
    return this.underActivity.users;
  }

  get overCash() {
    return this.overActivity.attributedAmount;
  }

  get underCash() {
    return this.underActivity.attributedAmount;
  }
}
