import styled from 'styled-components';
import { FeatureCollection } from '../../services/superdraft-api/features.endpoints';
import Typography from '@mui/material/Typography';
import DataPresentation from '../../components/DataPresentation';
import FeatureDetail from '../../components/FeatureDetail';
import SDModal from '../../components/SDModal';
import useFeatureManagementPage from './useFeatureManagementPage';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import CreateFeature from '../../components/CreateFeature';

const S = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
  Content: styled.div``,
  Dialog: styled(SDModal)`
    & .MuiPaper-root {
      max-width: 1200px;
      padding: unset;
    }
  `,
  Header: styled.header`
    display: flex;
    justify-content: space-between;
  `,
};

const FeatureManagementPage = () => {
  const {
    featureCollections,
    featuresColumns,
    isLoading,
    isError,
    error,
    setSelectedFeatureCollection,
    selectedFeatureCollection,
    clearSelection,
    creating,
    showCreate,
    hideCreate,
  } = useFeatureManagementPage();

  return (
    <>
      {/* MODALS */}
      {selectedFeatureCollection && (
        <S.Dialog open={true} onClose={clearSelection}>
          <FeatureDetail featureCollection={selectedFeatureCollection} />
        </S.Dialog>
      )}
      {creating && (
        <S.Dialog open={true} onClose={hideCreate}>
          <CreateFeature close={hideCreate} selectFeature={setSelectedFeatureCollection} />
        </S.Dialog>
      )}

      {/* COMPONENT */}
      <S.Wrapper data-testid='FeatureManagementPage'>
        <S.Header>
          <Typography variant='h1'>Feature Management</Typography>
          <Button onClick={showCreate} variant='contained' startIcon={<Add />}>
            Add Feature
          </Button>
        </S.Header>

        <S.Content>
          {isLoading && <Typography>Loading</Typography>}
          {isError && <Typography> Error: ${JSON.stringify(error)}</Typography>}

          {featureCollections && (
            <DataPresentation
              tableId='featureToggle'
              columns={featuresColumns}
              rows={featureCollections}
              searchKey='name'
              getRowId={(r: FeatureCollection) => r.name}
              onRowClick={({ row }: { row: FeatureCollection }) =>
                setSelectedFeatureCollection(row)
              }
              disableSelectionOnClick
            />
          )}
        </S.Content>
      </S.Wrapper>
    </>
  );
};

export default FeatureManagementPage;
export const FeatureDetailDialogWrapper = S.Dialog;
