import { useGetSportsQuery } from '../../services/superdraft-api/sports.endpoints';
import { useGetPropsPlayersQuery } from '../../services/superdraft-api/propsPlayers.endpoints';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate } from 'react-router-dom';
import { PropUIPlayer } from '../../types/until-npm-update/prop/propPlayer';

export const usePropsPlayersList = () => {
  const navigate = useNavigate()
  const { data: sports } = useGetSportsQuery();
  const { data: players, isLoading, isError, error } = useGetPropsPlayersQuery(sports || skipToken);

  const onRowClick = ({ row }: { row: PropUIPlayer }) => navigate(`${row.playerId}`);

  return {
    players,
    onRowClick,
    isLoading,
    isError,
    error,
  };
};
