import {
  Environment,
  GetFeaturesApiPayload,
} from '../../superdraft-api/api-payload-types/features.types';

export const features: GetFeaturesApiPayload[] = [
  {
    ownerEmail: 'raguayo@superdraft.io',
    environment: Environment.production,
    toggleName: 'propThrottling',
    globallyEnabled: true,
    uuid: '69e1be60-7e94-4e9d-8284-98a364728941',
    enabledIds: [],
  },
  {
    ownerEmail: 'raguayo@superdraft.io',
    environment: Environment.staging,
    toggleName: 'propThrottling',
    globallyEnabled: true,
    uuid: 'cd22bf8c-d988-4e81-baef-082a6c5db2fa',
    enabledIds: [],
  },
  {
    ownerEmail: 'avanderwall@superdraft.io',
    environment: Environment.production,
    toggleName: 'ABE-1151-lineupIdOnEntry',
    globallyEnabled: true,
    uuid: '393ef889-57df-44dc-bcbb-603424785766',
    enabledIds: [],
  },
  {
    ownerEmail: 'avanderwall@superdraft.io',
    environment: Environment.staging,
    toggleName: 'ABE-1151-lineupIdOnEntry',
    globallyEnabled: true,
    uuid: 'b0ca15c2-afa7-4452-960c-22a10abc9d7f',
    enabledIds: [],
  },
  {
    ownerEmail: 'jwilson@superdraft.io',
    environment: Environment.staging,
    toggleName: 'validatePropChoice',
    globallyEnabled: true,
    uuid: '94f827da-30d9-4ee4-ae18-35972377918b',
    enabledIds: [],
  },
  {
    ownerEmail: 'jwilson@superdraft.io',
    environment: Environment.production,
    toggleName: 'webCashierRework',
    globallyEnabled: true,
    uuid: 'a390c4a6-9957-4996-8c0e-c2f0ec09eaaa',
    enabledIds: [],
  },
  {
    ownerEmail: 'jwilson@superdraft.io',
    environment: Environment.staging,
    toggleName: 'webCashierRework',
    globallyEnabled: true,
    uuid: '76fc5370-8d0a-42a4-b102-9a97579fac68',
    enabledIds: [],
  },
  {
    ownerEmail: 'jwilson@superdraft.io',
    environment: Environment.test,
    toggleName: 'webCashierRework',
    globallyEnabled: true,
    uuid: 'ed626814-d623-4fd6-9895-c06418bb4228',
    enabledIds: [],
  },
  {
    ownerEmail: 'jwilson@superdraft.io',
    environment: Environment.staging,
    toggleName: 'featureToggle2',
    globallyEnabled: true,
    uuid: '26e36afc-93b2-43a9-b8e5-a1817a867c08',
    enabledIds: [],
  },
  {
    ownerEmail: 'bmewhinney@superdraft.io',
    environment: Environment.production,
    toggleName: 'failedDepositFlag',
    globallyEnabled: true,
    uuid: '5bf554c6-3c55-4299-8f7c-1db97e4a6497',
    enabledIds: ['145865'],
  },
  {
    ownerEmail: 'bmewhinney@superdraft.io',
    environment: Environment.staging,
    toggleName: 'failedDepositFlag',
    globallyEnabled: false,
    uuid: '5bf554c6-3c55-4299-8f7c-1db97e4a6497',
    enabledIds: ['100997'],
  },
  {
    ownerEmail: 'jwilson@superdraft.io',
    environment: Environment.staging,
    toggleName: 'PropsWrite',
    globallyEnabled: true,
    uuid: '1045a957-c14f-4a02-96ab-e995f7580b51',
    enabledIds: [],
  },
  {
    ownerEmail: 'bmewhinney@superdraft.io',
    environment: Environment.staging,
    toggleName: 'SuperdraftCoreWallet',
    globallyEnabled: true,
    uuid: '5c39fa52-733a-4202-9cd1-6a02e558d95d',
    enabledIds: [],
  },
  {
    ownerEmail: 'jwilson@superdraft.io',
    environment: Environment.staging,
    toggleName: 'GeneralCronOptimizedSchedule',
    globallyEnabled: true,
    uuid: '00700913-426f-4bee-87ca-4dc713d7f372',
    enabledIds: [],
  },
  {
    ownerEmail: 'avanderwall@superdraft.io',
    environment: Environment.production,
    toggleName: 'propEntryRewrite',
    globallyEnabled: false,
    uuid: '953eb014-3fb8-4bb1-ab28-b07de08905c3',
    enabledIds: [],
  },
  {
    ownerEmail: 'avanderwall@superdraft.io',
    environment: Environment.staging,
    toggleName: 'propEntryRewrite',
    globallyEnabled: false,
    uuid: 'c2cc0161-d7bd-46af-bd38-ae2bae51d0b7',
    enabledIds: [],
  },
  {
    ownerEmail: 'avanderwall@superdraft.io',
    environment: Environment.production,
    toggleName: 'pgaStartTimeBeginningOfEvent',
    globallyEnabled: false,
    uuid: '4eef2349-fe34-454d-9264-5578372893aa',
    enabledIds: [],
  },
  {
    ownerEmail: 'avanderwall@superdraft.io',
    environment: Environment.staging,
    toggleName: 'pgaStartTimeBeginningOfEvent',
    globallyEnabled: false,
    uuid: '8c66d0ed-bfd7-41e0-916e-633bd01709d5',
    enabledIds: [],
  },
  {
    ownerEmail: 'tester1@test.com',
    environment: Environment.staging,
    toggleName: 'TestToggle',
    globallyEnabled: true,
    uuid: 'abf19c81-ff52-4b13-988b-9b7b39f214d1',
    enabledIds: [],
  },
  {
    ownerEmail: 'avanderwall@superdraft.io',
    environment: Environment.production,
    toggleName: 'adamv.TestToggle',
    globallyEnabled: false,
    uuid: '2ba469b8-580e-41b9-952d-4b998d27168b',
    enabledIds: [],
  },
  {
    ownerEmail: 'avanderwall@superdraft.io',
    environment: Environment.staging,
    toggleName: 'adamv.TestToggle',
    globallyEnabled: false,
    uuid: 'b6e6b630-5e50-4489-ab55-eef3f1351f6d',
    enabledIds: [],
  },
  {
    ownerEmail: 'avanderwall@superdraft.io',
    environment: Environment.test,
    toggleName: 'adamv.TestToggle',
    globallyEnabled: true,
    uuid: '1c8a1840-f03f-4740-8eae-4136d3c40025',
    enabledIds: [],
  },
];
