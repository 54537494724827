import { createServer } from 'miragejs';
import ApiConstants from '../superdraft-api/apiConstants';
import { AppRegistry, factories, models } from './factories';
import { features } from './fixtures/features.fixtures';
import { coreUrl } from '../../application/environmentConfig';
import { mockSetFeatureHandler } from './factories/feature.factory';
import { PropAdminDashBoard } from '@superdraft/superdraft-dfs-data-types';
import { Server } from 'miragejs/server';
import { allSports } from './fixtures/sports.fixtures';
import { faker } from '@faker-js/faker';
import { mockPropActivitySummary } from './factories/playerProp.factory';
import { useMirage } from './helpers';
import { mockGetPlayerDetailHandler } from './factories/propsPlayer.factory';

const {
  playerProps: { getPropsDashboard, getPropsPlayers, getPropsPlayerDetail, getPropsEvents },
  features: { getAllFeatures, setFeature, deleteFeature },
  sports: { getSports },
} = ApiConstants;

export const createMockServer = ({ ...options } = {}): Server<AppRegistry> =>
  createServer<typeof models, typeof factories>({
    models,
    factories,
    fixtures: { features },
    seeds(server) {
      server.loadFixtures();
      // creates props and playerDetails along with the player
      server.createList('propsPlayer', 10);
      server.createList('propsEvent', 10);
    },
    routes() {
      this.passthrough('https://cognito-idp.us-east-1.amazonaws.com/');
      this.passthrough(`${coreUrl}/**`);

      this.urlPrefix = coreUrl;

      const { mockEndpoint } = useMirage({}, this);

      mockEndpoint(getSports, () => allSports);

      // player props (dashboard)
      mockEndpoint(
        getPropsDashboard,
        (schema): PropAdminDashBoard => ({
          activeGridRecords: schema.all('playerProp').models,
          openAction: faker.datatype.number({ min: 4000, max: 12000, precision: 2 }),
          openProps: faker.datatype.number({ min: 50, max: 1000 }),
          activeSummaryBySport: mockPropActivitySummary,
        }),
      );

      // props players, events
      mockEndpoint(getPropsPlayers, schema => schema.all('propsPlayer').models);
      mockEndpoint(getPropsEvents, schema => schema.all('propsEvent').models);
      mockEndpoint(getPropsPlayerDetail, mockGetPlayerDetailHandler);

      // features
      mockEndpoint(getAllFeatures, schema => schema.all('feature').models);
      mockEndpoint(setFeature, mockSetFeatureHandler);
      mockEndpoint(deleteFeature, () => null);
    },
    ...options,
  });
