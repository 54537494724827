import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { Outlet, Route, Routes } from 'react-router-dom';
import PropsListPage from '../PropsListPage/PropsListPage';
import { TabsRouter } from '../../components/TabsRouter';
import PropsPlayerList from '../PropsPlayerList';
import PropsEventSchedule from '../PropsEventSchedule';
import PlayerDetailPage from '../PlayerDetailPage';

const S = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
};

const PlayerPropsPage = () => {
  return (
    <S.Wrapper data-testid='PlayerPropsPage'>
      <Typography variant='h1'>Player Props</Typography>

      <TabsRouter
        tabs={[
          { title: 'Props', path: 'player-props', element: <PropsListPage />, index: true },
          { title: 'Player List', path: 'players', element: <PropsPlayerList /> },
          { title: 'Event Schedule', path: 'events', element: <PropsEventSchedule /> },
        ]}
      />

      <Routes>
        <Route path='players/:playerId' element={<PlayerDetailPage />} />
      </Routes>

      <Outlet />
    </S.Wrapper>
  );
};

export default PlayerPropsPage;
