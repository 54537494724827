import { capitalize } from '@mui/material';
import { addWeeks, isBefore } from 'date-fns';

export const dollarFormatter = (value: string | number, digits = 2) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: digits,
  }).format(Number(value));

export const GridDollarFormatter = (p: { value: string | number }, digits = 2) =>
  dollarFormatter(p.value, digits);

export const GridCapitalizeFormatter = (p: { value: string }) => capitalize(p.value);

export const weekBasedDateFormat = (dateStr: string) => {
  const date = new Date(dateStr);
  const today = new Date();
  const nextWeek = addWeeks(today, 1);

  const format: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
  };

  if (isBefore(date, nextWeek)) {
    format.weekday = 'short';
  } else {
    format.month = 'short';
    format.day = 'numeric';
  }

  return date.toLocaleDateString('en-US', format);
};

export const GridDateFormatter = (p: { value: string }) => weekBasedDateFormat(p.value);
