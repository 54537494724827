import { PropAdminActiveGridRecord } from '@superdraft/superdraft-dfs-data-types/lib/dashBoards/props';

// these are pasted from the network response 7/13/22. that response includes some other types than `fantasy-prop`
// but those have been removed because they correctly cause typescript errors
export const playerProps: PropAdminActiveGridRecord[] = [
  {
    status: 'open',
    sportId: '4',
    eventId: '1424274507',
    question: {
      img: 'https://fanzcallassets.s3.amazonaws.com/playerImages/all/small/937647.png',
      lblQuest: 'Jayson Tatum Total Points?',
      shortLabel: 'J. Tatum Total Points?',
      type: 'fantasy-player-ou-points',
    },
    propId: 'f0edd8d334fa6aa9e661ebea',
    playerName: 'Jayson Tatum',
    groupId: 'f94d57f541da74f7a984f91d',
    playerId: 937647,
    teamId: 2,
    teamAbbr: 'BOS',
    actualStatsValue: 0,
    winningRequirementValue: 12,
    line: {
      name: 'Points',
      value: 12,
    },
    choiceActivity: {
      under: {
        choiceId: '76dde30225b9236779baebac',
        choiceType: 'under',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
      over: {
        choiceId: '5f2cbc58c1bba21739c8b4e7',
        choiceType: 'over',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
    },
  },
  {
    status: 'open',
    sportId: '4',
    eventId: '1424274507',
    question: {
      img: 'https://fanzcallassets.s3.amazonaws.com/playerImages/all/small/937647.png',
      lblQuest: 'Jayson Tatum Total Rebounds?',
      shortLabel: 'J. Tatum Total Rebounds?',
      type: 'fantasy-player-ou-rebounds',
    },
    propId: '74cb2d137c357695010064e3',
    playerName: 'Jayson Tatum',
    groupId: '74cb2d137c357695010064e3',
    playerId: 937647,
    teamId: 2,
    teamAbbr: 'BOS',
    actualStatsValue: 0,
    winningRequirementValue: 1,
    line: {
      name: 'Rebounds',
      value: 1,
    },
    choiceActivity: {
      under: {
        choiceId: 'f6b110e8cfd1ac35da857d75',
        choiceType: 'under',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
      over: {
        choiceId: '20e80eea210fa2aaecc64416',
        choiceType: 'over',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
    },
  },
  {
    status: 'open',
    sportId: '4',
    eventId: '1424274507',
    question: {
      img: 'https://fanzcallassets.s3.amazonaws.com/playerImages/all/small/937647.png',
      lblQuest: 'Jayson Tatum Total Pick the winning team (Including OT)?',
      shortLabel: 'J. Tatum Total Pick the winning team (Including OT)?',
      type: 'outright-team',
    },
    propId: '3ab8d496652dab3d7112e877',
    playerName: 'Jayson Tatum',
    groupId: 'cb4efe654a7bee1119f50175',
    playerId: 937647,
    teamId: 2,
    teamAbbr: 'BOS',
    actualStatsValue: 0,
    winningRequirementValue: 1000,
    line: {
      name: 'Pick the winning team (Including OT)',
      value: 1000,
    },
    choiceActivity: {
      under: {
        choiceId: 'dd80ad582dcc5316a4f38570',
        choiceType: 'under',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
      over: {
        choiceId: '31bd92c189894b7be8516933',
        choiceType: 'over',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
    },
  },
  {
    status: 'open',
    sportId: '4',
    eventId: '1424274507',
    question: {
      img: 'https://fanzcallassets.s3.amazonaws.com/playerImages/all/small/937647.png',
      lblQuest: 'Jayson Tatum Total Blocks?',
      shortLabel: 'J. Tatum Total Blocks?',
      type: 'fantasy-player-ou-blocks',
    },
    propId: '057b45583eb7021de3e6d6b0',
    playerName: 'Jayson Tatum',
    groupId: '0f9df991edf8456ecd350e8c',
    playerId: 937647,
    teamId: 2,
    teamAbbr: 'BOS',
    actualStatsValue: 0,
    winningRequirementValue: 1000,
    line: {
      name: 'Blocks',
      value: 1000,
    },
    choiceActivity: {
      under: {
        choiceId: '9a2d441a2f930ac32ed77e8a',
        choiceType: 'under',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
      over: {
        choiceId: '404c0fd270599c03422bbd4f',
        choiceType: 'over',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
    },
  },
  {
    status: 'open',
    sportId: '4',
    eventId: '1424274507',
    question: {
      img: 'https://fanzcallassets.s3.amazonaws.com/playerImages/all/small/937647.png',
      lblQuest: 'Jayson Tatum Total Blocks?',
      shortLabel: 'J. Tatum Total Blocks?',
      type: 'fantasy-player-ou-blocks',
    },
    propId: 'b6515577f52a469f4f04e059',
    playerName: 'Jayson Tatum',
    groupId: '0f9df991edf8456ecd350e8c',
    playerId: 937647,
    teamId: 2,
    teamAbbr: 'BOS',
    actualStatsValue: 0,
    winningRequirementValue: 1000,
    line: {
      name: 'Blocks',
      value: 1000,
    },
    choiceActivity: {
      under: {
        choiceId: 'c0136e903ceabf45714821e9',
        choiceType: 'under',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
      over: {
        choiceId: 'cc0523732803861de9e87a8b',
        choiceType: 'over',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
    },
  },
  {
    status: 'open',
    sportId: '4',
    eventId: '1424274507',
    question: {
      img: 'https://fanzcallassets.s3.amazonaws.com/playerImages/all/small/883436.png',
      lblQuest: 'Jaylen Brown Total Blocks?',
      shortLabel: 'J. Brown Total Blocks?',
      type: 'fantasy-player-ou-blocks',
    },
    propId: '1c4e8806c5d1a34269d6a053',
    playerName: 'Jaylen Brown',
    groupId: '19d9d86b60ba6929c7daae03',
    playerId: 883436,
    teamId: 2,
    teamAbbr: 'BOS',
    actualStatsValue: 0,
    winningRequirementValue: 2,
    line: {
      name: 'Blocks',
      value: 2,
    },
    choiceActivity: {
      under: {
        choiceId: '7b3f6d0059dffadaefa97d7d',
        choiceType: 'under',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
      over: {
        choiceId: '122054a93d36a225f6a9c95e',
        choiceType: 'over',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
    },
  },
  {
    status: 'open',
    sportId: '10',
    eventId: '100000229',
    question: {
      img: 'https://fanzcallassets.s3.amazonaws.com/playerImages/all/small/140000926.png',
      lblQuest: 'Tagir Ulanbekov Total Takedowns?',
      shortLabel: 'T. Ulanbekov Total Takedowns?',
      type: 'fantasy-player-ou-takedowns',
    },
    propId: 'bf911c0a20c698376ba19182',
    playerName: 'Tagir Ulanbekov',
    groupId: 'f034c0896d2cf4a535f7d154',
    playerId: 140000926,
    teamId: 140000926,
    teamAbbr: 'No Nickname',
    actualStatsValue: 0,
    winningRequirementValue: 2.5,
    line: {
      name: 'Takedowns',
      value: 2.5,
    },
    choiceActivity: {
      under: {
        choiceId: '6fb3878deefee8a992a22bc7',
        choiceType: 'under',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
      over: {
        choiceId: '769299c412dd4b36f72b4117',
        choiceType: 'over',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
    },
  },
  {
    status: 'open',
    sportId: '10',
    eventId: '100000229',
    question: {
      img: 'https://fanzcallassets.s3.amazonaws.com/playerImages/all/small/140000632.png',
      lblQuest: 'Tim Elliott Total Takedowns?',
      shortLabel: 'T. Elliott Total Takedowns?',
      type: 'fantasy-player-ou-takedowns',
    },
    propId: '42c63580bc4d358aceb23cb7',
    playerName: 'Tim Elliott',
    groupId: '42c63580bc4d358aceb23cb7',
    playerId: 140000632,
    teamId: 140000632,
    teamAbbr: 'No Nickname',
    actualStatsValue: 0,
    winningRequirementValue: 1.5,
    line: {
      name: 'Takedowns',
      value: 1.5,
    },
    choiceActivity: {
      under: {
        choiceId: 'ba23a3857ad8bbd859c04cba',
        choiceType: 'under',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
      over: {
        choiceId: 'ace5764912bb1c4eea028dec',
        choiceType: 'over',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
    },
  },
  {
    status: 'open',
    sportId: '3',
    eventId: '3108017499',
    question: {
      img: 'https://fanzcallassets.s3.amazonaws.com/playerImages/all/small/975434.png',
      lblQuest: 'Zach Aston-Reese Total Assists?',
      shortLabel: 'Z. Aston-Reese Total Assists?',
      type: 'fantasy-player-ou-assists',
    },
    propId: '17925af5686b6bf59159db08',
    playerName: 'Zach Aston-Reese',
    groupId: '17925af5686b6bf59159db08',
    playerId: 975434,
    teamId: 4969,
    teamAbbr: 'Pit',
    actualStatsValue: 0,
    winningRequirementValue: 50,
    line: {
      name: 'Assists',
      value: 50,
    },
    choiceActivity: {
      under: {
        choiceId: '05cdfe6dafd163b20de290d4',
        choiceType: 'under',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
      over: {
        choiceId: '9a446664c86aec14aee252ae',
        choiceType: 'over',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
    },
  },
  {
    status: 'open',
    sportId: '3',
    eventId: '487616213',
    question: {
      img: 'https://fanzcallassets.s3.amazonaws.com/playerImages/all/small/680827.png',
      lblQuest: 'Matt Murray Total Pick the winning team (Including OT)?',
      shortLabel: 'M. Murray Total Pick the winning team (Including OT)?',
      type: 'outright-team',
    },
    propId: '00ea1a094924d0bf005958ea',
    playerName: 'Matt Murray',
    groupId: '00ea1a094924d0bf005958ea',
    playerId: 680827,
    teamId: 4967,
    teamAbbr: 'Ott',
    actualStatsValue: 0,
    winningRequirementValue: 1,
    line: {
      name: 'Pick the winning team (Including OT)',
      value: 1,
    },
    choiceActivity: {
      under: {
        choiceId: '5c3a04e94d183810644dcd26',
        choiceType: 'under',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
      over: {
        choiceId: 'cdf8e33383f622a232a0fbff',
        choiceType: 'over',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
    },
  },
  {
    status: 'open',
    sportId: '3',
    eventId: '3590061196',
    question: {
      img: 'https://fanzcallassets.s3.amazonaws.com/playerImages/all/small/543471.png',
      lblQuest: 'Cam Talbot Total Points?',
      shortLabel: 'C. Talbot Total Points?',
      type: 'fantasy-player-ou-points',
    },
    propId: '14ec8743d71e2d97d371a919',
    playerName: 'Cam Talbot',
    groupId: '14ec8743d71e2d97d371a919',
    playerId: 543471,
    teamId: 4983,
    teamAbbr: 'Min',
    actualStatsValue: 0,
    winningRequirementValue: 1,
    line: {
      name: 'Points',
      value: 1,
    },
    choiceActivity: {
      under: {
        choiceId: '6df9fc72571a725fe59fc9ed',
        choiceType: 'under',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
      over: {
        choiceId: '35ec3e612af0c9ad28efde09',
        choiceType: 'over',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
    },
  },
  {
    status: 'open',
    sportId: '3',
    eventId: '3590061196',
    question: {
      img: 'https://fanzcallassets.s3.amazonaws.com/playerImages/all/small/607980.png',
      lblQuest: 'Jordan Binnington Total Pick the winning team (Including OT)?',
      shortLabel: 'J. Binnington Total Pick the winning team (Including OT)?',
      type: 'outright-team',
    },
    propId: 'd6ba387d653de323343e91ac',
    playerName: 'Jordan Binnington',
    groupId: 'd6ba387d653de323343e91ac',
    playerId: 607980,
    teamId: 4972,
    teamAbbr: 'StL',
    actualStatsValue: 0,
    winningRequirementValue: 1,
    line: {
      name: 'Pick the winning team (Including OT)',
      value: 1,
    },
    choiceActivity: {
      under: {
        choiceId: 'e76ea1b81f486746f38d1c67',
        choiceType: 'under',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
      over: {
        choiceId: 'ca40f079f6b5124e387455e8',
        choiceType: 'over',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
    },
  },
  {
    status: 'open',
    sportId: '3',
    eventId: '142601713',
    question: {
      img: 'https://fanzcallassets.s3.amazonaws.com/playerImages/all/small/659865.png',
      lblQuest: 'Andrei Vasilevskiy Total Saves?',
      shortLabel: 'A. Vasilevskiy Total Saves?',
      type: 'fantasy-player-ou-saves',
    },
    propId: 'affd43b2cc611f774913668a',
    playerName: 'Andrei Vasilevskiy',
    groupId: 'affd43b2cc611f774913668a',
    playerId: 659865,
    teamId: 4973,
    teamAbbr: 'TB',
    actualStatsValue: 0,
    winningRequirementValue: 2,
    line: {
      name: 'Saves',
      value: 2,
    },
    choiceActivity: {
      under: {
        choiceId: '45cf3e6aa25fe7dedf7728a4',
        choiceType: 'under',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
      over: {
        choiceId: '6cc90a57928dab2a4295aa09',
        choiceType: 'over',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
    },
  },
  {
    status: 'open',
    sportId: '3',
    eventId: '142601713',
    question: {
      img: 'https://fanzcallassets.s3.amazonaws.com/playerImages/all/small/379958.png',
      lblQuest: 'Brian Elliott Total Blocked Shots?',
      shortLabel: 'B. Elliott Total Blocked Shots?',
      type: 'fantasy-player-ou-blocks',
    },
    propId: '5ed1337a5562bd930ca3d32a',
    playerName: 'Brian Elliott',
    groupId: '5ed1337a5562bd930ca3d32a',
    playerId: 379958,
    teamId: 4973,
    teamAbbr: 'TB',
    actualStatsValue: 0,
    winningRequirementValue: 88,
    line: {
      name: 'Blocked Shots',
      value: 88,
    },
    choiceActivity: {
      under: {
        choiceId: '5c70cc1d2f243c59bf67cc59',
        choiceType: 'under',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
      over: {
        choiceId: '60421e65dd05037383c00533',
        choiceType: 'over',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
    },
  },
  {
    status: 'open',
    sportId: '3',
    eventId: '142601713',
    question: {
      img: 'https://fanzcallassets.s3.amazonaws.com/playerImages/all/small/456624.png',
      lblQuest: 'Steven Stamkos Total Shots?',
      shortLabel: 'S. Stamkos Total Shots?',
      type: 'fantasy-player-ou-shots',
    },
    propId: '8ece06c388cff8d08d86cf6b',
    playerName: 'Steven Stamkos',
    groupId: '8ece06c388cff8d08d86cf6b',
    playerId: 456624,
    teamId: 4973,
    teamAbbr: 'TB',
    actualStatsValue: 0,
    winningRequirementValue: 9,
    line: {
      name: 'Shots',
      value: 9,
    },
    choiceActivity: {
      under: {
        choiceId: '00292e55e7bc257f056be5e1',
        choiceType: 'under',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
      over: {
        choiceId: '19ee8e8e7b25327deae8bc72',
        choiceType: 'over',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
    },
  },
  {
    status: 'open',
    sportId: '3',
    eventId: '142601713',
    question: {
      img: 'https://fanzcallassets.s3.amazonaws.com/playerImages/all/small/545448.png',
      lblQuest: 'Sergei Bobrovsky Total Shots?',
      shortLabel: 'S. Bobrovsky Total Shots?',
      type: 'fantasy-player-ou-shots',
    },
    propId: 'e3fa3fb524eb45199172d5a9',
    playerName: 'Sergei Bobrovsky',
    groupId: 'e3fa3fb524eb45199172d5a9',
    playerId: 545448,
    teamId: 4979,
    teamAbbr: 'Fla',
    actualStatsValue: 0,
    winningRequirementValue: 32,
    line: {
      name: 'Shots',
      value: 32,
    },
    choiceActivity: {
      under: {
        choiceId: 'e327fa0313c2c4f113f24139',
        choiceType: 'under',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
      over: {
        choiceId: '81f64d4b3ca697e7c56d6283',
        choiceType: 'over',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
    },
  },
  {
    status: 'open',
    sportId: '3',
    eventId: '142601713',
    question: {
      img: 'https://fanzcallassets.s3.amazonaws.com/playerImages/all/small/456624.png',
      lblQuest: 'Steven Stamkos Total Assists?',
      shortLabel: 'S. Stamkos Total Assists?',
      type: 'fantasy-player-ou-assists',
    },
    propId: '868e7670bc43dede25a1b7c5',
    playerName: 'Steven Stamkos',
    groupId: '868e7670bc43dede25a1b7c5',
    playerId: 456624,
    teamId: 4973,
    teamAbbr: 'TB',
    actualStatsValue: 0,
    winningRequirementValue: 74,
    line: {
      name: 'Assists',
      value: 74,
    },
    choiceActivity: {
      under: {
        choiceId: '7981e0acb2855fe5dc49c9f0',
        choiceType: 'under',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
      over: {
        choiceId: '7990f05637238888ec2bda7c',
        choiceType: 'over',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
    },
  },
  {
    status: 'open',
    sportId: '3',
    eventId: '142601713',
    question: {
      img: 'https://fanzcallassets.s3.amazonaws.com/playerImages/all/small/545448.png',
      lblQuest: 'Sergei Bobrovsky Total Goals?',
      shortLabel: 'S. Bobrovsky Total Goals?',
      type: 'fantasy-player-ou-goals',
    },
    propId: '2f9961d90b1395bc9d88ef61',
    playerName: 'Sergei Bobrovsky',
    groupId: '2f9961d90b1395bc9d88ef61',
    playerId: 545448,
    teamId: 4979,
    teamAbbr: 'Fla',
    actualStatsValue: 0,
    winningRequirementValue: 12.2,
    line: {
      name: 'Goals',
      value: 12.2,
    },
    choiceActivity: {
      under: {
        choiceId: '9ec951824a74bd81eab35c82',
        choiceType: 'under',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
      over: {
        choiceId: 'e1a0347c6a55fe6a9be6ae73',
        choiceType: 'over',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
    },
  },
  {
    status: 'open',
    sportId: '3',
    eventId: '142601713',
    question: {
      img: 'https://fanzcallassets.s3.amazonaws.com/playerImages/all/small/607616.png',
      lblQuest: 'Nikita Kucherov Total Goals?',
      shortLabel: 'N. Kucherov Total Goals?',
      type: 'fantasy-player-ou-goals',
    },
    propId: '2a34c5a099d8f835b88c4dab',
    playerName: 'Nikita Kucherov',
    groupId: '2a34c5a099d8f835b88c4dab',
    playerId: 607616,
    teamId: 4973,
    teamAbbr: 'TB',
    actualStatsValue: 0,
    winningRequirementValue: 2.3,
    line: {
      name: 'Goals',
      value: 2.3,
    },
    choiceActivity: {
      under: {
        choiceId: '91231e736f8ef0401ef66eb4',
        choiceType: 'under',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
      over: {
        choiceId: '4c4bfb2f269d67a64b4d15e7',
        choiceType: 'over',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
    },
  },
  {
    status: 'open',
    sportId: '3',
    eventId: '142601713',
    question: {
      img: 'https://fanzcallassets.s3.amazonaws.com/playerImages/all/small/504246.png',
      lblQuest: 'Victor Hedman Total Goals?',
      shortLabel: 'V. Hedman Total Goals?',
      type: 'fantasy-player-ou-goals',
    },
    propId: 'a1841e85c8d19a4ba562e84d',
    playerName: 'Victor Hedman',
    groupId: 'a1841e85c8d19a4ba562e84d',
    playerId: 504246,
    teamId: 4973,
    teamAbbr: 'TB',
    actualStatsValue: 0,
    winningRequirementValue: 6.6,
    line: {
      name: 'Goals',
      value: 6.6,
    },
    choiceActivity: {
      under: {
        choiceId: 'e4ae21e0136b9b73417df531',
        choiceType: 'under',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
      over: {
        choiceId: '766f1aa0f2ac6f7d8a3c5854',
        choiceType: 'over',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
    },
  },
  {
    status: 'open',
    sportId: '3',
    eventId: '142601713',
    question: {
      img: 'https://fanzcallassets.s3.amazonaws.com/playerImages/all/small/831385.png',
      lblQuest: 'Brayden Point Total Goals?',
      shortLabel: 'B. Point Total Goals?',
      type: 'fantasy-player-ou-goals',
    },
    propId: 'fc0d264f5a869fb86c6505be',
    playerName: 'Brayden Point',
    groupId: 'fc0d264f5a869fb86c6505be',
    playerId: 831385,
    teamId: 4973,
    teamAbbr: 'TB',
    actualStatsValue: 0,
    winningRequirementValue: 99,
    line: {
      name: 'Goals',
      value: 99,
    },
    choiceActivity: {
      under: {
        choiceId: '58aac031aec191a4185b0b14',
        choiceType: 'under',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
      over: {
        choiceId: 'd1cc59ac9a7edb01e8903f61',
        choiceType: 'over',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
    },
  },
  {
    status: 'open',
    sportId: '3',
    eventId: '142601713',
    question: {
      img: 'https://fanzcallassets.s3.amazonaws.com/playerImages/all/small/329656.png',
      lblQuest: 'Claude Giroux Total Goals?',
      shortLabel: 'C. Giroux Total Goals?',
      type: 'fantasy-player-ou-goals',
    },
    propId: '8391d4b35c21a657d22aa00b',
    playerName: 'Claude Giroux',
    groupId: '8391d4b35c21a657d22aa00b',
    playerId: 329656,
    teamId: 4979,
    teamAbbr: 'FLA',
    actualStatsValue: 0,
    winningRequirementValue: 33,
    line: {
      name: 'Goals',
      value: 33,
    },
    choiceActivity: {
      under: {
        choiceId: 'e2ce7c4171dd673bfd240958',
        choiceType: 'under',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
      over: {
        choiceId: '86f8851868459bb456d36131',
        choiceType: 'over',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
    },
  },
  {
    status: 'open',
    sportId: '3',
    eventId: '142601713',
    question: {
      img: 'https://fanzcallassets.s3.amazonaws.com/playerImages/all/small/386808.png',
      lblQuest: 'Patric Hornqvist Total Assists?',
      shortLabel: 'P. Hornqvist Total Assists?',
      type: 'fantasy-player-ou-assists',
    },
    propId: '8f922d1588e08bd501b77734',
    playerName: 'Patric Hornqvist',
    groupId: '8f922d1588e08bd501b77734',
    playerId: 386808,
    teamId: 4979,
    teamAbbr: 'Fla',
    actualStatsValue: 0,
    winningRequirementValue: 33,
    line: {
      name: 'Assists',
      value: 33,
    },
    choiceActivity: {
      under: {
        choiceId: '611f6cd2c3a9080165f91fbf',
        choiceType: 'under',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
      over: {
        choiceId: '313d072ad6987477992a2cc8',
        choiceType: 'over',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
    },
  },
  {
    status: 'open',
    sportId: '3',
    eventId: '142601713',
    question: {
      img: 'https://fanzcallassets.s3.amazonaws.com/playerImages/all/small/741405.png',
      lblQuest: 'Aleksander Barkov Total Hits?',
      shortLabel: 'A. Barkov Total Hits?',
      type: 'fantasy-player-ou-hits',
    },
    propId: 'a15854406c9de007154555dd',
    playerName: 'Aleksander Barkov',
    groupId: 'a15854406c9de007154555dd',
    playerId: 741405,
    teamId: 4979,
    teamAbbr: 'Fla',
    actualStatsValue: 0,
    winningRequirementValue: 1.2,
    line: {
      name: 'Hits',
      value: 1.2,
    },
    choiceActivity: {
      under: {
        choiceId: '3d6c71b56ad741f32c55e9c4',
        choiceType: 'under',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
      over: {
        choiceId: '52d9376a34879bc3c3aa264e',
        choiceType: 'over',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
    },
  },
  {
    status: 'open',
    sportId: '5',
    eventId: '1656363532',
    question: {
      img: 'https://fanzcallassets.s3.amazonaws.com/playerImages/all/small/873852.png',
      lblQuest: 'Bryson DeChambeau Total Round 4 Birdies?',
      shortLabel: 'B. DeChambeau Total Round 4 Birdies?',
      type: 'fantasy-player-ou-r4birdies',
    },
    propId: '0517b798e2584d4bb957fca4',
    playerName: 'Bryson DeChambeau',
    groupId: '0517b798e2584d4bb957fca4',
    playerId: 873852,
    teamId: 873852,
    teamAbbr: 'USA',
    actualStatsValue: 0,
    winningRequirementValue: 5,
    line: {
      name: 'Round 4 Birdies',
      value: 5,
    },
    choiceActivity: {
      under: {
        choiceId: 'f00b3fbcf430336d97a6f2f1',
        choiceType: 'under',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
      over: {
        choiceId: 'ff89734d2acbf52a68e68c07',
        choiceType: 'over',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
    },
  },
  {
    status: 'open',
    sportId: '4',
    eventId: '3236283986',
    question: {
      img: 'https://fanzcallassets.s3.amazonaws.com/playerImages/all/small/338365.png',
      lblQuest: 'Stephen Curry Total Points?',
      shortLabel: 'S. Curry Total Points?',
      type: 'fantasy-player-ou-points',
    },
    propId: 'ac1072e4196f45843f755237',
    playerName: 'Stephen Curry',
    groupId: 'ac1072e4196f45843f755237',
    playerId: 338365,
    teamId: 9,
    teamAbbr: 'GSW',
    actualStatsValue: 0,
    winningRequirementValue: 38.5,
    line: {
      name: 'Points',
      value: 38.5,
    },
    choiceActivity: {
      under: {
        choiceId: '513401d599c1342a404dd7be',
        choiceType: 'under',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
      over: {
        choiceId: '49bf0f5a6f991f4dfe69b921',
        choiceType: 'over',
        attributedAmount: 0,
        attributedRewardPoint: 0,
        attributedCoin: 0,
        entries: 0,
        users: 0,
        maxEntryAmount: 0,
      },
    },
  },
];
