import { GridDateFormatter, GridDollarFormatter } from '../../utils/gridFormatters';

export const propsEventsColumns = [
  {
    field: 'sportName',
    headerName: 'Sport',
    flex: 0
  },
  {
    field: 'eventName',
    headerName: 'Event'
  },
  {
    field: 'startTimeUTC',
    headerName: 'Start Time',
    valueFormatter: GridDateFormatter,
    // todo: set a constant "current date" in chromatic, and give the base date to faker
    cellClassName: 'chromatic-ignore'
  },
  {
    field: 'openProps',
    headerName: 'Open Props'
  },
  {
    field: 'openAction',
    headerName: 'Total Cash',
    valueFormatter: GridDollarFormatter
  }
] as const;
