import { Factory, hasMany, Model } from 'miragejs';
import { faker } from '@faker-js/faker';
import { getFakeMatchupStr, getFakeSportId } from '../faker.helpers';
import { ModelDefinition } from 'miragejs/-types';
import { PropUIPlayer } from '../../../types/until-npm-update/prop/propPlayer';
import pluralize from 'pluralize';
import { PropAdminActiveGridRecord } from '@superdraft/superdraft-dfs-data-types/lib/dashBoards/props';
import { RouteHandler } from 'miragejs/server';
import { AppRegistry } from './index';

// The backend has propPlayers and the player details stored separately,
// but we manage them as one model in our mirage mocks.
export const propsPlayerFactory = Factory.extend({
  sportId: () => getFakeSportId(),
  playerId: i => i,
  playerName: () => faker.name.findName(),
  eventName: getFakeMatchupStr,
  startTimeUTC: () => faker.date.future(0.05),
  openAction: () => faker.datatype.number({ min: 100, max: 2000, precision: 2 }),
  openProps: () => faker.datatype.number({ min: 5, max: 50 }),
  uniformNumber: () => faker.datatype.number({ min: 1, max: 99 }),
  teamName: () => `${faker.address.cityName()} ${pluralize(faker.word.noun(), 2)}`,
  position: () => faker.word.noun(),
  playerImg: 'https://fanzcallassets.s3.amazonaws.com/playerImages/mlb/small/873673.png', // url
  teamImg: 'https://fanzcallassets.s3.amazonaws.com/team-logos/all/363.png', // url
  playerProps: [] as PropAdminActiveGridRecord[],
  playerPropIds: [] as string[],
  // @ts-ignore
  afterCreate(playerDetail, server) {
    const { playerId, playerName, sportId } = playerDetail.attrs as PropUIPlayer;
    playerDetail.update({
      playerProps: server.createList('playerProp', 3, { playerId, playerName, sportId }),
    });
  },
});

export const propsPlayerModel: ModelDefinition<PropUIPlayer> = Model.extend({
  playerProps: hasMany(),
});

export const mockGetPlayerDetailHandler: RouteHandler<AppRegistry> = (schema, request) => {
  const playerFromList = schema
    .all('propsPlayer')
    .models.find(p => p.playerId === parseInt(request.params.playerId));

  if (!playerFromList) throw new Error('error in mock GET playerDetail');

  const { playerPropIds, ...playerDetail } = playerFromList.attrs;
  const playerProps = playerPropIds.map((id: string) =>
    schema.all('playerProp').models.find(p => p.attrs.id === id),
  );
  return { ...playerDetail, playerProps };
};
