import { ModelDefinition } from 'miragejs/-types';
import { Factory, Model } from 'miragejs';
import {
  Environment,
  GetFeaturesApiPayload,
} from '../../superdraft-api/api-payload-types/features.types';
import { RouteHandler } from 'miragejs/server';
import { AppRegistry } from './index';
import { faker } from '@faker-js/faker';

export const featureFactory = Factory.extend({
  ownerEmail: () => faker.internet.email(),
  environment: Environment.production,
  toggleName: () => `${faker.word.noun(6)}Feature`,
  globallyEnabled: true,
  uuid: i => `${i}`,
  enabledIds: [],
});

export const mockSetFeatureHandler: RouteHandler<AppRegistry> = (schema, request) => {
  const feature = JSON.parse(request.requestBody);
  const { name, environment } = request.params;
  const existing = schema
    .all('feature')
    .models.find(f => f.toggleName === name && f.environment === environment);

  if (!existing) {
    const newFeature = schema.create('feature', {
      ...feature,
      environment,
      toggleName: name,
      uuid: `${Math.random()}`,
    });
    return newFeature.attrs;
  } else {
    existing.update(feature);
    return existing.attrs;
  }
};

export const featureModel: ModelDefinition<GetFeaturesApiPayload> = Model.extend({});

export type FeatureModelType = { feature: typeof featureModel };
export type FeatureFactoryType = { feature: typeof featureFactory };
