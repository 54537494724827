export const allSports = [
  {
    _id: '62bc6c0800fe5e93e6187852',
    sportId: 1,
    sportName: 'NFL',
    createdAt: '2022-06-29T15:13:12.157Z',
    updatedAt: '2022-06-29T15:13:12.157Z',
    __v: 0,
  },
  {
    _id: '62bc6c0800fe5e93e6187853',
    sportId: 2,
    sportName: 'MLB',
    createdAt: '2022-06-29T15:13:12.157Z',
    updatedAt: '2022-06-29T15:13:12.157Z',
    __v: 0,
  },
  {
    _id: '62bc6c0800fe5e93e6187854',
    sportId: 3,
    sportName: 'NHL',
    createdAt: '2022-06-29T15:13:12.158Z',
    updatedAt: '2022-06-29T15:13:12.158Z',
    __v: 0,
  },
  {
    _id: '62bc6c0800fe5e93e6187855',
    sportId: 4,
    sportName: 'NBA',
    createdAt: '2022-06-29T15:13:12.158Z',
    updatedAt: '2022-06-29T15:13:12.158Z',
    __v: 0,
  },
  {
    _id: '62bc6c0800fe5e93e6187856',
    sportId: 5,
    sportName: 'PGA',
    createdAt: '2022-06-29T15:13:12.158Z',
    updatedAt: '2022-06-29T15:13:12.158Z',
    __v: 0,
  },
  {
    _id: '62bc6c0800fe5e93e6187857',
    sportId: 7,
    sportName: 'NASCAR',
    createdAt: '2022-06-29T15:13:12.158Z',
    updatedAt: '2022-06-29T15:13:12.158Z',
    __v: 0,
  },
  {
    _id: '62bc6c0800fe5e93e618785a',
    sportId: 10,
    sportName: 'MMA',
    createdAt: '2022-06-29T15:13:12.158Z',
    updatedAt: '2022-06-29T15:13:12.158Z',
    __v: 0,
  },
  {
    _id: '62bc6c0800fe5e93e618785b',
    sportId: 11,
    sportName: 'Prediction',
    createdAt: '2022-06-29T15:13:12.158Z',
    updatedAt: '2022-06-29T15:13:12.158Z',
    __v: 0,
  },
  {
    _id: '62bc6c0800fe5e93e618785c',
    sportId: 12,
    sportName: 'WWE',
    createdAt: '2022-06-29T15:13:12.158Z',
    updatedAt: '2022-06-29T15:13:12.158Z',
    __v: 0,
  },
  {
    _id: '62bc6c0800fe5e93e618785d',
    sportId: 13,
    sportName: 'Poker',
    createdAt: '2022-06-29T15:13:12.159Z',
    updatedAt: '2022-06-29T15:13:12.159Z',
    __v: 0,
  },
  {
    _id: '62bc6c0800fe5e93e618785e',
    sportId: 14,
    sportName: 'Big3',
    createdAt: '2022-06-29T15:13:12.159Z',
    updatedAt: '2022-06-29T15:13:12.159Z',
    __v: 0,
  },
  {
    _id: '62bc6c0800fe5e93e618785f',
    sportId: 15,
    sportName: 'NCAA Football',
    createdAt: '2022-06-29T15:13:12.159Z',
    updatedAt: '2022-06-29T15:13:12.159Z',
    __v: 0,
  },
  {
    _id: '62bc6c0800fe5e93e6187860',
    sportId: 16,
    sportName: 'NCAA Basketball',
    createdAt: '2022-06-29T15:13:12.159Z',
    updatedAt: '2022-06-29T15:13:12.159Z',
    __v: 0,
  },
  {
    _id: '62bc6c0800fe5e93e6187858',
    sportId: 8,
    sportName: 'WNBA',
    createdAt: '2022-06-29T15:13:12.158Z',
    updatedAt: '2022-06-29T15:13:12.158Z',
    __v: 0,
  },
  {
    _id: '62bc6c0800fe5e93e6187859',
    sportId: 9,
    sportName: 'Stocks',
    createdAt: '2022-06-29T15:13:12.158Z',
    updatedAt: '2022-06-29T15:13:12.158Z',
    __v: 0,
  },
];
