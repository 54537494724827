import styled from 'styled-components';
import { useGetPropsPlayerDetailQuery } from '../../services/superdraft-api/propsPlayers.endpoints';
import Typography from '@mui/material/Typography';
import DataPresentation from '../../components/DataPresentation';
import { PropAdminActiveGridRecord } from '@superdraft/superdraft-dfs-data-types/lib/dashBoards/props';
import { playerDetailColumns } from './playerDetailColumns';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import PlayerDetailHeader from '../../components/PlayerDetailHeader';

const S = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
};

const PlayerDetailPage = () => {
  const { playerId } = useParams();

  const {
    data: player,
    isLoading,
    isError,
    error,
  } = useGetPropsPlayerDetailQuery(playerId ? parseInt(playerId) : skipToken);

  return (
    <S.Wrapper data-testid={`PlayerDetailPage-${playerId}`}>
      {isLoading && <Typography>Loading...</Typography>}
      {isError && <Typography> Error: ${JSON.stringify(error)}</Typography>}
      {player && (
        <>
          <PlayerDetailHeader player={player} />
          <DataPresentation
            tableId='playerDetail'
            columns={playerDetailColumns}
            rows={player.playerProps}
            getRowId={(r: PropAdminActiveGridRecord) => r.propId}
            searchKey='line'
          />
        </>
      )}
    </S.Wrapper>
  );
};

export default PlayerDetailPage;
