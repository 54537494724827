import { useGetSportsQuery } from '../../services/superdraft-api/sports.endpoints';
import { useGetPropsEventsQuery } from '../../services/superdraft-api/playerProps.endpoints';
import { skipToken } from '@reduxjs/toolkit/query';

export const usePropsEventsList = () => {
  const { data: sports } = useGetSportsQuery();
  const { data: events, isLoading, isError, error } = useGetPropsEventsQuery(sports || skipToken);
  
  return {
    events,
    isLoading,
    isError,
    error,
  };
};
