import { RouteHandler, Server } from 'miragejs/server';
import { ApiEndpoint } from '../superdraft-api/apiConstants';
import { AppRegistry } from './factories';
import { PretenderFixed } from './mirage.types';
import { coreUrl } from '../../application/environmentConfig';
import { storybookMirage } from './storybook/helpers';

/**
 * Allows an endpoint to be mocked using an endpoint definition object from `ApiConstants`.
 * Note that this can't be written as an arrow function because it needs `this`.
 */
export function unboundMockEndpoint(
  this: Server,
  endpoint: ApiEndpoint,
  handler: RouteHandler<AppRegistry>,
) {
  const { method, queryFn, params } = endpoint;

  const urlSegments = params?.map(p => `:${p}`);
  const url = urlSegments ? queryFn(...urlSegments) : queryFn();

  this[method](coreUrl + url, handler);
}

export function throwError(this: Server, endpoint: ApiEndpoint) {
  unboundMockEndpoint.bind(this)(endpoint, () => {
    throw new Error('mock error');
  });
}

export const useMirage = (
  { loadFixtures = true }: MirageConfig = {},
  _server?: Server<AppRegistry>,
) => {
  const server = _server || (storybookMirage.server as Server);
  if (loadFixtures) server.loadFixtures();
  
  return {
    server,
    mockEndpoint: unboundMockEndpoint.bind(server),
    throwError: throwError.bind(server),
  };
};

export const createGetRequests =
  (server: Server) =>
    (...args: [number?, number?]) =>
      getRequests(server, ...args);

export const getRequests = (
  // the mirage server instance.
  server: Server,
  // if provided, an assertion will be made that this many requests have been handled
  expectCount?: number,
  // if provided, the function will return the request at the given index.
  // uses `Array.prototype.at`, so negative indices may be used.
  returnRequestAt?: number,
) => {
  const { handledRequests } = server.pretender as PretenderFixed;

  if (expectCount && expectCount > -1) {
    expect(handledRequests.length).toEqual(expectCount);
  }

  if (returnRequestAt) {
    return handledRequests.at(returnRequestAt);
  }

  return handledRequests;
};
export type MirageConfig = { loadFixtures?: boolean };
