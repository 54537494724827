import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { dollarFormatter } from '../../utils/gridFormatters';

const S = {
  Wrapper: styled.div`
    display: flex;
    gap: 1ch;
  `,
};

export const CashWithPercentage = ({ value, total }: { value: number; total: number }) => {
  const percent = (value / total) * 100;
  return (
    <S.Wrapper>
      <Typography fontSize={14}>{dollarFormatter(value)}</Typography>
      <Typography variant='body2'>({percent.toFixed(1)}%)</Typography>
    </S.Wrapper>
  );
};
