export const statuses = ['Open', 'In Progress', 'Cancelled', 'Closed'];

export const columns = [
  { field: 'sport', headerName: 'Sport', flex: 0 },
  { field: 'playerName', headerName: 'Player' },
  { field: 'line', headerName: 'Line' },
  { field: 'boost', headerName: 'Boost' },
  { field: 'totalCash', headerName: 'Total Cash' },
  { field: 'totalUsers', headerName: 'Total Users' },
  { field: 'overCash', headerName: 'Over Cash' },
  { field: 'underCash', headerName: 'Under Cash' },
  { field: 'status', headerName: 'Status' },
]


// use weekdays for the mock rows so that we can easily
// search player names with useful search strings
const weekdays = [...Array(7).keys()].map(i => {
  const date = new Date(Date.UTC(2017, 0, i + 1)); // starts on a saturday
  return date.toLocaleDateString('en-US', { weekday: 'long' });
});

export const rows = [...Array(10).keys()].map(i => ({
  id: i,
  sport: i % 3 ? 'NBA' : 'MLB',
  playerName: `${i % 2 ? 'Mr' : 'Ms'}. ${weekdays[i % 7]}`,
  line: `${i} Total points`,
  boost: 'Odds',
  totalCash: `$${i * 100}`,
  totalUsers: 50,
  overCash: '$100',
  underCash: `$${i * 9}`,
  status: statuses[i % 4],
}));
