import { SportsApiPayload } from './sports.endpoints';

export type SportsMap = Record<number, SportsApiPayload>;
export type HasSportId = { sportId: number | string };
export type WithSportName<T extends HasSportId> = T & { sportName: string };
export type ArrayWithSportName<T extends HasSportId> = WithSportName<T>[];
export type ObjectWithSportName<T extends HasSportId> = Record<string, WithSportName<T>>;

export const getNameForSport = (json: HasSportId, sports: SportsMap): string => {
  try {
    return sports[parseInt(json.sportId as string)].sportName;
  } catch (e) {
    console.error(`Invalid sportId ${json.sportId} found in payload`, json);
    return 'Unknown sport';
  }
};

export const getNameForSportId = (sportId: string | number, sports: SportsMap): string =>
  getNameForSport({ sportId }, sports);

export const addSportNamesToArray = <A extends HasSportId>(
  array: A[],
  _meta: unknown,
  sports: SportsMap,
) => {
  return array.map(p => ({ ...p, sportName: getNameForSportId(p.sportId, sports) }));
};
