import { faker } from '@faker-js/faker';
import { allSports } from './fixtures/sports.fixtures';

export const getFakeSportId = (allowLong = false) => {
  const validSportIds = allSports
    .filter(sport => allowLong || sport.sportName.length === 3)
    .map(s => s.sportId);
  return `${faker.helpers.arrayElement(validSportIds)}`;
};

export const getFakeMatchupStr = () =>
  [faker.random.alpha(3).toUpperCase(), faker.random.alpha(3).toUpperCase()].join(' @ ');
