type PropStatus = 'open' | 'closed' | 'cancelled';
type PropType = string; // todo
type PropActorType = 'player' | string; // todo
type PropResult = 'won' | 'lost' | string; // todo
export enum PropChoiceOption {
  over = 'over',
  under = 'under',
}

export type PropsPlayerApiPayload = {
  probable: boolean;
  info: unknown[];
  sportRadarId: string;
  statsId: string;
  srId: string | null;
  playerId: number;
  fName: string;
  lName: string;
  posAbbr: string;
  teamId: number;
  teamAbbr: string;
};
type PropsQuestion = {
  img: string;
  lblQuest: string;
  shortLabel: string;
  type: string;
};
type PropStats = {
  propType: string;
  name: string;
  scoringKey: string;
  statProvider: string;
  key: string;
  templateId: string;
  value: number;
};
type PropChoice = {
  odds: 2;
  type: PropChoiceOption;
  actor: {
    liveInfo: string;
    actorType: PropActorType;
    actorId: string;
    lblAct: string;
    abrev: string;
    parameterType: string;
    parameter: string;
    img: string;
    result: PropResult;
    winningRequirement: [PropStats];
    actualStats: [PropStats];
    id: string;
  };
  id: string;
  cash: number;
  coin: number;
  winningCash: number;
  winningCoin: number;
};
export type PlayerPropApiPayload = {
  _id: string;
  autoResolution: true;
  name: string;
  status: PropStatus;
  type: PropType;
  startTimeUTC: string; // Date?
  sportId: string;
  typeLabel: string;
  eventId: string;
  player: PropsPlayerApiPayload;
  question: PropsQuestion;
  choices: PropChoice[];
  id: string;
  groupId: string;
  createdAt: string; // Date?
  updatedAt: string; // Date?
  __v: number;
};
