import adminApi from './adminApi';
import ApiConstants from './apiConstants';
import { addSportNamesToArray, ArrayWithSportName, SportsMap } from './sportName.helpers';
import { PropAdminPlayerGridRecord } from '../../types/until-npm-update/dashBoards/propsPlayers';
import { PropUIPlayer } from '../../types/until-npm-update/prop/propPlayer';
import { PlayerProp, PlayerPropJson } from '../../json-builder-models/PlayerProp';
import { PropPlayerId } from '@superdraft/superdraft-dfs-data-types';

export type ProcessedPropUIPlayer = Omit<PropUIPlayer, 'playerProps' | 'sportName'> & {
  playerProps: Omit<PlayerPropJson, 'sportName'>[];
};

export const { useGetPropsPlayersQuery, useGetPropsPlayerDetailQuery } = adminApi.injectEndpoints({
  endpoints: build => ({
    getPropsPlayers: build.query<ArrayWithSportName<PropAdminPlayerGridRecord>, SportsMap>({
      query: ApiConstants.playerProps.getPropsPlayers.queryFn,
      transformResponse: addSportNamesToArray,
    }),
    getPropsPlayerDetail: build.query<ProcessedPropUIPlayer, PropPlayerId>({
      query: ApiConstants.playerProps.getPropsPlayerDetail.queryFn,
      transformResponse: (player: PropUIPlayer) => ({
        ...player,
        playerProps: player.playerProps.map(PlayerProp.build),
      }),
    }),
  }),
});
