import styled from 'styled-components';
import Tabs from '@mui/material/Tabs';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { ReactElement, useMemo } from 'react';
import Tab from '@mui/material/Tab';

export const S = {
  Tabs: styled(Tabs)`
    .MuiTabs-indicator {
      height: 4px;
      border-radius: 8px 8px 0 0;
    }

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  `,
  Link: styled(Link)`
    && {
      color: black;
      text-transform: capitalize;
      font-weight: 500;
      width: 140px;
    }
  `,
};

export type TabsConfig = {
  title: string;
  path: string;
  index?: boolean;
  element: ReactElement;
};

type Props = {
  tabs: TabsConfig[];
  otherPathsExpected?: boolean;
};

export const TabsRouter = ({ tabs, otherPathsExpected = true }: Props) => {
  const location = useLocation();
  const indexPath = tabs.find(t => t.index);
  const currentPath = location.pathname.split('/').pop() || indexPath?.path;

  // To render the tab routes alongside other routes that shoudn't show these tabs.
  const tabPaths = useMemo(() => tabs.map(t => t.path), [tabs]);
  const tabFound = currentPath && tabPaths.includes(currentPath);
  if (!tabFound) {
    if (!otherPathsExpected) {
      console.warn(
        `A tab router attempted to render at a path, ${currentPath}, that it didn't expect. Expected paths:`,
        tabPaths,
      );
    }
    return null;
  }

  return (
    <>
      <S.Tabs value={currentPath}>
        {tabs.map(({ title, path, index }) => (
          <Tab key={path} label={title} to={index ? '' : path} value={path} component={S.Link} />
        ))}
      </S.Tabs>

      <Routes>
        {tabs.map(({ path, index, element }) => (
          <Route key={path} path={index ? '' : path} index={index} element={element} />
        ))}
      </Routes>
    </>
  );
};
