import { useGetSportsQuery } from '../../services/superdraft-api/sports.endpoints';
import { useGetPropsDashboardQuery } from '../../services/superdraft-api/playerProps.endpoints';
import { skipToken } from '@reduxjs/toolkit/query';
import { GridCellParams } from '@mui/x-data-grid';
import { PropUIPlayer } from '../../types/until-npm-update/prop/propPlayer';
import { useNavigate } from 'react-router-dom';

export const usePlayerPropsList = () => {
  const navigate = useNavigate();
  const { data: sports } = useGetSportsQuery();
  const {
    data: dashboard,
    isLoading,
    isError,
    error,
  } = useGetPropsDashboardQuery(sports || skipToken);

  const onCellClick = ({ field, row }: GridCellParams<unknown, PropUIPlayer>) => {
    if (field === 'playerName') {
      navigate(`players/${row.playerId}`);
    }
  };

  return {
    playerProps: dashboard?.activeGridRecords,
    dashboard,
    onCellClick,
    isLoading,
    isError,
    error,
  };
};
