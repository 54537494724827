import { Factory, Model } from 'miragejs';
import { faker } from '@faker-js/faker';
import { getFakeMatchupStr, getFakeSportId } from '../faker.helpers';
import { ModelDefinition } from 'miragejs/-types';
import { PropAdminEventGridRecord } from '../../../types/until-npm-update/dashBoards/propsEvents';

export const propsEventFactory = Factory.extend({
  eventId: i => `${i}`,
  sportId: () => getFakeSportId(),
  eventName: getFakeMatchupStr,
  startTimeUTC: () => faker.date.future(0.05),
  openAction: () => faker.datatype.number({ min: 1000, max: 4000, precision: 2 }),
  openProps: () => faker.datatype.number({ min: 50, max: 100 }),
});

export const propsEventModel: ModelDefinition<PropAdminEventGridRecord> = Model.extend({});
