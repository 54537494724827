import {
  ChoiceActivity,
  PropAdminActiveGridRecord,
} from '@superdraft/superdraft-dfs-data-types/lib/dashBoards/props';
import { ModelDefinition } from 'miragejs/-types';
import { belongsTo, Factory, Model } from 'miragejs';
import { playerProps } from '../fixtures/playerProps.fixtures';
import { statuses } from '../../../components/DataTable/mockTableData';
import { faker } from '@faker-js/faker';
import pluralize from 'pluralize';
import { PropChoiceOption } from '../../superdraft-api/api-payload-types/playerProps.types';
import { capitalize } from 'lodash';
import { Functionized } from '../../../utils/types/utility.types';
import { allSports } from '../fixtures/sports.fixtures';
import { getFakeSportId } from '../faker.helpers';

const fakeChoice = (choice: PropChoiceOption): Functionized<ChoiceActivity> => ({
  choiceId: () => faker.datatype.uuid(),
  choiceType: choice,
  attributedAmount: () => faker.datatype.number(5000),
  attributedRewardPoint: () => faker.datatype.number(5000),
  attributedCoin: () => faker.datatype.number(5000),
  entries: () => faker.datatype.number(1000),
  users: () => faker.datatype.number(500),
  maxEntryAmount: () => faker.datatype.number(100),
});

const fakeChoiceActivity = Object.values(PropChoiceOption).reduce(
  (obj, choice) => ({
    ...obj,
    [choice]: fakeChoice(choice),
  }),
  {},
);

// we've defined everything except "question", which we certainly don't need in the dashboard anyway
export const playerPropFactory = Factory.extend({
  ...playerProps[0],
  id: i => `${i}`, // required for mirage/typescript, though not needed by UI or api
  status: () => statuses[faker.datatype.number(100) % 4],
  sportId: () => getFakeSportId(),
  eventId: (i: number) => i,
  propId: i => `${i}`,
  playerName: () => faker.name.findName(),
  groupId: () => faker.datatype.uuid(),
  playerId: (i: number) => i,
  teamId: () => faker.datatype.number(10),
  teamAbbr: () => faker.random.alpha(3),
  actualStatsValue: () => faker.datatype.number(20),
  winningRequirementValue: () => faker.datatype.number(20),
  line(this: PropAdminActiveGridRecord) {
    return {
      name: capitalize(pluralize(faker.word.noun(), 2)),
      value: this.winningRequirementValue,
    };
  },
  choiceActivity: fakeChoiceActivity,
});

export const mockPropActivitySummary = allSports.slice(0, 5).reduce(
  (obj, sport) => ({
    ...obj,
    [sport.sportId]: {
      ...sport,
      attributedAmount: faker.datatype.number({ min: 1000, max: 5000, precision: 2 }),
    },
  }),
  {},
);

export const playerPropModel: ModelDefinition<PropAdminActiveGridRecord> = Model.extend({
  propsPlayer: belongsTo(),
});
