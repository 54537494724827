import styled from 'styled-components';
import { Avatar, Paper } from '@mui/material';
import { ProcessedPropUIPlayer } from '../../services/superdraft-api/propsPlayers.endpoints';
import Typography from '@mui/material/Typography';
import { dollarFormatter } from '../../utils/gridFormatters';

const S = {
  Wrapper: styled(Paper)`
    padding: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    overflow: hidden;
  `,
  Pane: styled.div<{ centered?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: ${ p => p.centered && 'center' };
  `,
  Subtitle: styled(Typography).attrs({ variant: 'body2' })`
    font-size: 14px;
    text-transform: uppercase;
  `,
  Title: styled(Typography)`
    && {
      font-size: 28px;
      font-weight: 700;
    }
  `,
  Avatar: styled(Avatar)`
    && {
      height: 84px;
      width: 84px;
      box-sizing: content-box;
      border: 2px solid rgba(0, 0, 0, 0.1);
    }
  `,
  TeamLogoWrapper: styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    width: 222px;
  `,
  TeamLogo: styled(Avatar)`
    && {
      height: 222px;
      width: 222px;
      box-sizing: content-box;
      opacity: 0.3;
      border: 2px solid rgba(0, 0, 0, 0.1);
      position: absolute;
    }
  `
};

const PlayerDetailHeader = ({ player }: { player: ProcessedPropUIPlayer }) => {
  const {
    playerName,
    playerImg,
    playerId,
    teamImg,
    teamName,
    position,
    openProps,
    openAction,
    uniformNumber
  } = player;
  const infoStr = [teamName, `#${ uniformNumber }`, position].join(' | ');
  return (
    <S.Wrapper data-testid={ `PlayerDetailHeader-${ playerId }` }>
      <S.Avatar alt={ playerName } src={ playerImg } />
      <S.Pane>
        <S.Title>{ playerName }</S.Title>
        <S.Subtitle>{ infoStr }</S.Subtitle>
      </S.Pane>
      <S.Pane centered>
        <S.Title>{ openProps }</S.Title>
        <S.Subtitle>Open Props</S.Subtitle>
      </S.Pane>
      <S.Pane centered>
        <S.Title>{ dollarFormatter(openAction) }</S.Title>
        <S.Subtitle>Open Action</S.Subtitle>
      </S.Pane>
      <S.TeamLogoWrapper>
        <S.TeamLogo alt={ teamName } src={ teamImg } />
      </S.TeamLogoWrapper>
    </S.Wrapper>
  );
};

export default PlayerDetailHeader;
