import { PlayerPropJson } from '../../json-builder-models/PlayerProp';
import { GridCapitalizeFormatter, GridDollarFormatter } from '../../utils/gridFormatters';
import { CashWithPercentage } from './CashWithPercentage';

type Cell = { row: PlayerPropJson };

export const playerDetailColumns = [
  {
    field: 'line',
    headerName: 'Line',
    valueGetter: ({ row: { line } }: Cell) => `${line.value} ${line.name}`,
  },
  {
    field: 'totalUsers',
    headerName: 'Users',
  },
  {
    field: 'totalCash',
    headerName: 'Total Cash',
    valueGetter: ({ row: { overCash, underCash } }: Cell) => overCash + underCash,
    valueFormatter: GridDollarFormatter,
  },
  {
    field: 'overCash',
    headerName: 'Over Cash',
    renderCell: ({ row: { totalCash, overCash } }: Cell) => (
      <CashWithPercentage value={overCash} total={totalCash} />
    ),
  },
  {
    field: 'underCash',
    headerName: 'Under Cash',
    renderCell: ({ row: { totalCash, underCash } }: Cell) => (
      <CashWithPercentage value={underCash} total={totalCash} />
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    valueFormatter: GridCapitalizeFormatter,
  },
] as const;
